@use "@/dealroadshow/ui/components/Landing/variables.scss";

.pageContainer {
  width: 100%;
  background-color: variables.$baseBackgroundColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pageContainerCustom {
  flex-direction: row;
  align-items: center;
}
